import React from "react";
import TitleBar from "../../components/TitleBar";
import AllReports from "../../components/Reports.js/AllReports";

function ReportAccounts() {
  return (
    <div>
      <TitleBar title="Reported Accounts" />
      <div className="mt-4"></div>
      <AllReports />
    </div>
  );
}

export default ReportAccounts;
