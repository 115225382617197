import { PaperClipIcon } from "@heroicons/react/20/solid";

const users = [
    {
        firstName:"Mubashir",
        lastName:"Siddiqui",
        username:"mubseoul"
    },
    {
        firstName:"Mubashir",
        lastName:"Siddiqui",
        username:"mubseoul"
    },
    {
        firstName:"Mubashir",
        lastName:"Siddiqui",
        username:"mubseoul"
    },

  ]

function UserInfo() {
  return (
    <div>
      <div className="bg-white rounded-md shadow px-4 py-3">
        <div>
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-semibold leading-6 text-themeGray">
                Sami Ahmed
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                Margot Foster
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-semibold leading-6 text-themeGray">
                Mobile#
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                726329302
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-semibold leading-6 text-themeGray">
                Email address
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                margotfoster@example.com
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-semibold leading-6 text-themeGray">
                Civil ID
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                SAR-2323
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-semibold leading-6 text-themeGray">
                Created Date
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                23/05/2024
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-semibold leading-6 text-themeGray">
                Status
              </dt>
              <dd className="mt-1 text-sm leading-6 text-green-600 font-semibold sm:col-span-2 sm:mt-0">
                Acitve
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mt-4"></div>
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
                <h3 className="font-medium text-lg">Account Info</h3>
          </div>

        </div>
        <div className="py-4">
            <div className="grid grid-cols-5 gap-12">
                <div className="flex justify-between">
                    <h4 className="text-sm font-semibold">Type:</h4>
                    <p className="text-sm">Seller</p>
                </div>
                <div className="flex justify-between">
                    <h4 className="text-sm font-semibold">Sub Type:</h4>
                    <p className="text-sm">Seller</p>
                </div>
                <div className="flex justify-between">
                    <h4 className="text-sm font-semibold">Seller Assitants:</h4>
                    <p className="text-sm">03</p>
                </div>
            </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        First Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Last Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        User Name
                      </th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white">
                    {users.map((user) => (
                      <tr key={user.username}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">{user.firstName}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.lastName}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.username}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
