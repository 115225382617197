import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showNotification } from "./notificationSlice";

export const addBanner = createAsyncThunk(
  "banners/addBanner",
  async (formData, { getState, dispatch }) => {
    const { token } = getState().auth;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/banners`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch(
        showNotification({ message: response.data.message, type: "success" })
      );
      return response.data.banner; // Return the newly created banner
    } catch (error) {
      dispatch(
        showNotification({ message: "Failed to add banner", type: "error" })
      );
      throw error;
    }
  }
);

export const fetchBanners = createAsyncThunk(
  "banners/fetchBanners",
  async ({ page, limit }, { getState, dispatch }) => {
    const { token } = getState().auth;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/banners?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      dispatch(
        showNotification({ message: "Failed to fetch banners", type: "error" })
      );
      throw error;
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "banners/deleteBanner",
  async (bannerId, { getState, dispatch }) => {
    const { token } = getState().auth;
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/banners/${bannerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        showNotification({
          message: "Banner deleted successfully!",
          type: "success",
        })
      );
      return bannerId;
    } catch (error) {
      dispatch(
        showNotification({ message: "Failed to delete banner", type: "error" })
      );
      throw error;
    }
  }
);

const bannersSlice = createSlice({
  name: "banners",
  initialState: {
    banners: [],
    pagination: { total: 0, page: 1, limit: 10 },
    loading: false,
    error: null,
  },
  reducers: {
    setPage(state, action) {
      state.pagination.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.banners = action.payload.banners;
        state.pagination.total = action.payload.pagination.total;
        state.loading = false;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addBanner.fulfilled, (state, action) => {
        state.banners = [action.payload, ...state.banners]; // Add new banner at the beginning
        state.pagination.total += 1;  // Update the total count
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.banners = state.banners.filter((banner) => banner._id !== action.payload); // Remove the deleted banner
        state.pagination.total -= 1;  // Decrease the total count
      });
  }  
});

export const { setPage } = bannersSlice.actions;
export default bannersSlice.reducer;
