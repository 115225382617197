import React from "react";
import CountrySelector from "../CountrySelector";

const countries = [
  { name: "kuwait", code: "ku", current: true },
  { name: "United States", code: "us", current: false },
];

export default function Bids() {
  return (
    <div className="bg-white px-4 py-4 border border-gray-100 shadow-md rounded-lg">
      {/* Header Area */}
      <div className="flex items-center justify-between">
        <h3 className="font-medium font-base">Bids Placed</h3>
        <CountrySelector />
      </div>

      <div className="mt-6">
        <div >
          <dt className="space-y-6">
            <dd className="flex items-center ">
              <div className="w-36 text-xs font-semibold">Auction 1</div>
              <div class="w-full bg-gray-100 rounded ">
                <div
                  class="bg-primaryBlue text-xs font-medium text-white text-center p-0.5 py-1 leading-none rounded-sm"
                  style={{ width: "45%" }}
                >
                  {" "}
                  45
                </div>
              </div>
            </dd>
            <dd className="flex items-center ">
              <div className="w-36 text-xs font-semibold">Auction 2</div>
              <div class="w-full bg-gray-100 rounded ">
                <div
                  class="bg-primaryBlue text-xs font-medium text-white text-center p-0.5 py-1 leading-none rounded-sm"
                  style={{ width: "60%" }}
                >
                  {" "}
                  60
                </div>
              </div>
            </dd>
            <dd className="flex items-center ">
              <div className="w-36 text-xs font-semibold">Auction 3</div>
              <div class="w-full bg-gray-100 rounded ">
                <div
                  class="bg-primaryBlue text-xs font-medium text-white text-center p-0.5 py-1 leading-none rounded-sm"
                  style={{ width: "45%" }}
                >
                  {" "}
                  45
                </div>
              </div>
            </dd>
            <dd className="flex items-center ">
              <div className="w-36 text-xs font-semibold">Auction 4</div>
              <div class="w-full bg-gray-100 rounded ">
                <div
                  class="bg-primaryBlue text-xs font-medium text-white text-center p-0.5 py-1 leading-none rounded-sm"
                  style={{ width: "35%" }}
                >
                  {" "}
                  32
                </div>
              </div>
            </dd>
            <dd className="flex items-center ">
              <div className="w-36 text-xs font-semibold">Auction 5</div>
              <div class="w-full bg-gray-100 rounded ">
                <div
                  class="bg-primaryBlue text-xs font-medium text-white text-center p-0.5 py-1 leading-none rounded-sm"
                  style={{ width: "70%" }}
                >
                  {" "}
                  100
                </div>
              </div>
            </dd>
            <dd className="flex items-center ">
              <div className="w-36 text-xs font-semibold">Auction 6</div>
              <div class="w-full bg-gray-100 rounded ">
                <div
                  class="bg-primaryBlue text-xs font-medium text-white text-center p-0.5 py-1 leading-none rounded-sm"
                  style={{ width: "10%" }}
                >
                  {" "}
                  2
                </div>
              </div>
            </dd>
            <dd className="flex items-center ">
              <div className="w-36 text-xs font-semibold">Auction 7</div>
              <div class="w-full bg-gray-100 rounded ">
                <div
                  class="bg-primaryBlue text-xs font-medium text-white text-center p-0.5 py-1 leading-none rounded-sm"
                  style={{ width: "100%" }}
                >
                  {" "}
                  200
                </div>
              </div>
            </dd>
          </dt>
        </div>
      </div>
    </div>
  );
}
