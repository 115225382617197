import AllNotifications from "../../../components/Notifications/AllNotifications";
import TitleBar from "../../../components/TitleBar";

function Notifications() {
    return ( 
        <div>
            <TitleBar title={'Notifications'} />
            <div className="mt-4"></div>
            <AllNotifications />
        </div>
     );
}

export default Notifications;