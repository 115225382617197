import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth);

  // If token is not available, redirect to the signin page
  if (!token) {
    return <Navigate to="/signin" />;
  }

  return children; // If logged in, render the requested component
};

export default PrivateRoute;
