import React, { useState } from "react";
import personImage from "./../../assets/images/user.png";
import { PlusIcon } from "@heroicons/react/24/outline";
import AddNewCoupon from "./AddNew";

const coupons = [
  {
    id: 1,
    code: "MUB20",
    name: "Offer 20% Off",
    seller: "waseem008",
    date: "03/08/2023",
  },
  {
    id: 1,
    code: "MUB20",
    name: "Offer 20% Off",
    seller: "waseem008",
    date: "03/08/2023",
  },
  {
    id: 1,
    code: "MUB20",
    name: "Offer 20% Off",
    seller: "waseem008",
    date: "03/08/2023",
  },
  {
    id: 1,
    code: "MUB20",
    name: "Offer 20% Off",
    seller: "waseem008",
    date: "03/08/2023",
  },
];

export default function AllCoupons() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="font-medium text-lg sr-only">Coupons</h3>
          </div>

          <div className="flex items-center">
            <button
              onClick={() => setIsOpen(true)}
              className="flex items-center text-xs font-semibold text-primaryDarkBlue"
            >
              <PlusIcon className="w-4 mr-2" /> Add New
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        Sr.#
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Code
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Coupon Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Seller Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white">
                    {coupons.map((coupon) => (
                      <tr key={coupon.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                          {coupon.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light flex items-center">
                          {coupon.code}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold">
                          {coupon.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {coupon.seller}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {coupon.date}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          <div className="flex items-center space-x-3">
                            <div>View</div>
                            <div>Edit</div>
                            <div>Delete</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewCoupon isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
