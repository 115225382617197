import React, {useState} from "react";
import CountrySelector from "../CountrySelector";
import bow from './../../assets/images/blow.png';
import bow2 from './../../assets/images/blow1.png';

const countries = [
  { name: "kuwait", code: "ku", current: true },
  { name: "United States", code: "us", current: false },
];

export default function AppUsers() {

  const [value, setValue] = useState(50);

  return (
    <div className="bg-white px-4 py-4 border border-gray-100 shadow-md rounded-lg">
      {/* Header Area */}
      <div className="flex items-center justify-between">
        <h3 className="font-medium font-base">App Users</h3>
        <CountrySelector />
      </div>
      <div className="mt-6"></div>

      <div className="px-8">
        <img className="w-full mb-4" src={bow} />
        <img className="w-full" src={bow2} />
      </div>
     

    </div>
  );
}
