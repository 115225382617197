import React from "react";
import TitleBar from "../../components/TitleBar";
import AllTransactions from "../../components/Transactions/AllTransactions";

function Transactions() {
  return (
    <div>
      <TitleBar title="Transactions" />
      <div className="mt-4"></div>
      <AllTransactions />
    </div>
  );
}

export default Transactions;
