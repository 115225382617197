import TitleBar from "../../components/TitleBar";

function Settings() {
    return ( 
        <div>
            <TitleBar title={'Settings'} />
            <div className="mt-4"></div>
        </div>
     );
}

export default Settings;