import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PhotoIcon } from '@heroicons/react/20/solid';
import { useDispatch } from 'react-redux';
import { updateCategory } from '../../redux/slices/categoriesSlice';
import { showNotification } from '../../redux/slices/notificationSlice';

function EditCategoryModal({ isOpen, setIsOpen, category }) {
  const dispatch = useDispatch();

  // Check if category exists and has an ID before initializing state
  useEffect(() => {
    if (!category?._id) {
      console.warn("Warning: Category ID is undefined");
    }
  }, [category]);

  const [formData, setFormData] = useState({
    name: category?.name?.en || '',
    description: category?.description?.en || '',
    coverPhoto: null,
  });
  const [imagePreview, setImagePreview] = useState(category?.coverPhoto || null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, coverPhoto: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prevent the API call if `category._id` is undefined
    if (!category?._id) {
      dispatch(showNotification({ message: "Category ID is missing!", type: "error" }));
      return;
    }

    const form = new FormData();
    form.append("name[en]", formData.name);
    form.append("description[en]", formData.description);
    if (formData.coverPhoto) form.append("coverPhoto", formData.coverPhoto);

    dispatch(updateCategory({ id: category._id, data: form }))
      .unwrap()
      .then(() => {
        dispatch(showNotification({ message: "Category updated successfully!", type: "success" }));
        setIsOpen(false);
      })
      .catch((error) => {
        const errorMessage = typeof error === "string" ? error : error?.message || "Failed to update category";
        dispatch(showNotification({ message: errorMessage, type: "error" }));
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" open={isOpen} onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="max-w-md w-full space-y-4 bg-white rounded-lg shadow-md p-6">
              <Dialog.Title className="text-lg font-medium">Edit Category</Dialog.Title>
              <form onSubmit={handleSubmit}>
                <div className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                      Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-themeOrange focus:outline-none sm:text-sm"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-900">
                      Cover photo (optional)
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 relative">
                      <input
                        id="file-upload"
                        name="coverPhoto"
                        type="file"
                        onChange={handleFileChange}
                        className="absolute inset-0 opacity-0 cursor-pointer"
                      />
                      {imagePreview ? (
                        <img src={imagePreview} alt="Preview" className="mx-auto h-24 w-24 object-cover rounded-md" />
                      ) : (
                        <div className="text-center">
                          <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                          <div className="mt-4 text-sm leading-6 text-gray-600">Upload a file</div>
                          <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-themeOrange focus:outline-none sm:text-sm"
                      required
                    />
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    className="text-sm font-medium text-gray-700"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-sm font-medium text-white bg-themeOrange rounded-md px-4 py-1"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditCategoryModal;
