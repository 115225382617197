import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/slices/authSlice';
import SignIn from './../../components/Auth/SignIn';

const SignInPage = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);

  const handleSignIn = (event) => {
    event.preventDefault();
    const { username, password } = event.target.elements;

    // Dispatch the login action with email and password
    dispatch(login({ email: username.value, password: password.value }));
  };

  return (
    <div>
      <SignIn handleSignIn={handleSignIn} loading={loading} error={error} />
    </div>
  );
};

export default SignInPage;
