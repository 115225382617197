import React from "react";
import TitleBar from "../../components/TitleBar";
import AllReports from "../../components/Reports.js/AllReports";

function Reports() {
  return (
    <div>
      <TitleBar title="Reports" />
      <div className="mt-4"></div>
      <AllReports />
    </div>
  );
}

export default Reports;
