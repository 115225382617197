const aunctions = [
    {
        id: 12136,
        title: "Apple Watch Series 7",
        creator: "Saleem Al mulla",
        duration: {
            start: "6/12/2024 | 8:00 AM",
            end: "6/15/2024 | 8:00 PM"
        },
        lots: 100,
        totalBids: 250,
        type: "Normal"
    },
    {
        id: 12136,
        title: "Apple Watch Series 7",
        creator: "Saleem Al mulla",
        duration: {
            start: "6/12/2024 | 8:00 AM",
            end: "6/15/2024 | 8:00 PM"
        },
        lots: 100,
        totalBids: 250,
        type: "Normal"
    },
    {
        id: 12136,
        title: "Apple Watch Series 7",
        creator: "Saleem Al mulla",
        duration: {
            start: "6/12/2024 | 8:00 AM",
            end: "6/15/2024 | 8:00 PM"
        },
        lots: 100,
        totalBids: 250,
        type: "Normal"
    },
    {
        id: 12136,
        title: "Apple Watch Series 7",
        creator: "Saleem Al mulla",
        duration: {
            start: "6/12/2024 | 8:00 AM",
            end: "6/15/2024 | 8:00 PM"
        },
        lots: 100,
        totalBids: 250,
        type: "Normal"
    },
    {
        id: 12136,
        title: "Apple Watch Series 7",
        creator: "Saleem Al mulla",
        duration: {
            start: "6/12/2024 | 8:00 AM",
            end: "6/15/2024 | 8:00 PM"
        },
        lots: 100,
        totalBids: 250,
        type: "Normal"
    }
  ]
  
  export default function Auctions() {
    return (
      <div className="px-4 sm:px-6 lg:px-8 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-4">
          <div className="sm:flex-auto">
                <h3 className="font-medium font-base">Recent Auctions</h3>
          </div>

        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-light text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-themeSemiGray">
                        Title
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-themeSemiGray">
                        Creator
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-themeSemiGray">
                        Duration
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-themeSemiGray">
                        Lots
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-themeSemiGray">
                        Total Bids
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-themeSemiGray">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-themeSemiGray">
                        Actions
                      </th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300 bg-white">
                    {aunctions.map((auction) => (
                      <tr key={auction.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-themeGray sm:pl-6 lg:pl-8">
                          {auction.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeGray font-medium">{auction.title}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeGray font-medium">{auction.creator}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeGray font-medium">
                            <div>{auction.duration.start}</div>
                            <div>{auction.duration.end}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeGray font-medium">{auction.lots}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeGray font-medium">{auction.totalBids}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeGray font-medium">{auction.type}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <a href="#" className="text-secondaryBlue hover:text-secondaryBlue">
                            View<span className="sr-only">, {auction.name}</span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  