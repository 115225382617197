import React from "react";
import CountrySelector from "../../components/CountrySelector";
import UserInfo from "../../components/Users/UserInfo";

function EditUser(props) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <h2 className="font-semibold text-2xl text-themeDark mr-3">User Detail</h2>
          <button className="text-sm font-medium text-white bg-themeOrange rounded-md px-4 py-1">Block Access</button>
        </div>

        <div className="flex justify-end items-center">
          <CountrySelector />
          <div className="ml-3">
            <span className="text-base text-themeGray">
              06/15/2024 | 09:30 PM
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4"></div>
      <UserInfo />
    </div>
  );
}

export default EditUser;
