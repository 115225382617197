import React from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import lotImage from './../../../assets/images/lot.png';

const lots = [
  {
    id: 1,
    name: ' Iphone 14 pro',
    buyNowPrice: '60 KD',
    reservePrice: '40 KD',
    bidIncrement: '5 KD',
    averageBid: '60 LD',
    totalBids: 200,
    status: 'Sold'
  },
  {
    id: 1,
    name: ' Iphone 14 pro',
    buyNowPrice: '60 KD',
    reservePrice: '40 KD',
    bidIncrement: '5 KD',
    averageBid: '60 LD',
    totalBids: 200,
    status: 'Sold'
  },
  {
    id: 1,
    name: ' Iphone 14 pro',
    buyNowPrice: '60 KD',
    reservePrice: '40 KD',
    bidIncrement: '5 KD',
    averageBid: '60 LD',
    totalBids: 200,
    status: 'Sold'
  },
  {
    id: 1,
    name: ' Iphone 14 pro',
    buyNowPrice: '60 KD',
    reservePrice: '40 KD',
    bidIncrement: '5 KD',
    averageBid: '60 LD',
    totalBids: 200,
    status: 'Sold'
  }
];

export default function AllLots({title}) {

  return (
    <div>
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="font-medium text-lg">Lot Details <span className="ml-4">99023</span></h3>
          </div>

          <div className="flex items-center">
            <button
              className="flex items-center text-xs font-semibold text-primaryDarkBlue"
            >
              <PlusIcon className="w-4 mr-2" /> Add New
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        Image
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Buy Now PRice
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Reserve Amount
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Bid Increment
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Average
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Bids
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white items-center">
                    {lots.map((lot) => (
                      <tr key={lot.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                          <img src={lotImage} />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light flex items-center">
                          {lot.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold">
                          {lot.buyNowPrice}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {lot.reservePrice}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {lot.bidIncrement}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {lot.averageBid}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {lot.totalBids}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {lot.status}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold flex items-center">
                          <Link to={'/auctions/'+lot.id} >View</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
