import AllCoupons from "../../../components/Coupons/AllCoupons";
import TitleBar from "../../../components/TitleBar";

function Coupons() {
    return ( 
        <div>
            <TitleBar title={'Coupons'} />
            <div className="mt-4"></div>
            <AllCoupons />
        </div>
     );
}

export default Coupons;