import React from "react"
import personImage from './../../assets/images/user.png';

const notifications = [
    {
        id: 1,
        title: 'Offer 20% Off',
        description: '20% off on all purchases',
        users: 232,
        date: "03/08/2023"
    },
    {
        id: 1,
        title: 'Offer 20% Off',
        description: '20% off on all purchases',
        users: 232,
        date: "03/08/2023"
    },
    {
        id: 1,
        title: 'Offer 20% Off',
        description: '20% off on all purchases',
        users: 232,
        date: "03/08/2023"
    },
    {
        id: 1,
        title: 'Offer 20% Off',
        description: '20% off on all purchases',
        users: 232,
        date: "03/08/2023"
    }

  ]
  
  export default function AllNotifications() {
    return (
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-5">
          <div className="sm:flex-auto">
                <h3 className="font-medium text-lg sr-only">All Notifications</h3>
          </div>

          <div className="flex justify-end items-center">
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        Sr.#
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Image
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Title
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Description
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Users
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                       Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Actions
                      </th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white">
                    {notifications.map((notification) => (
                      <tr key={notification.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                          {notification.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light flex items-center"><img src={personImage} /></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold">{notification.title}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{notification.description}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{notification.users}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{notification.date}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold">
                            <div className="flex items-center space-x-3">
                                <div>View</div>
                                <div>Edit</div>
                                <div>Delete</div>
                            </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  