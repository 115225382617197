import {
    Dialog,
    DialogPanel,
    DialogTitle,
  } from "@headlessui/react";
  import { Fragment } from "react";
  import { Transition } from "@headlessui/react";
  import { PhotoIcon } from "@heroicons/react/24/solid";

  function AddNewCoupon({ isOpen, setIsOpen }) {
    return (
      <div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>
  
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="max-w-md space-y-4 bg-white rounded-lg shadow-md p-4">
                  <DialogTitle className="text-lg font-medium">Add New Coupon</DialogTitle>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="w-full">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-themeOrange focus:outline-none focus:ring-themeOrange sm:text-sm"
                        placeholder="Coupon Name"
                      />
                    </div>
                    <div className="w-full">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-themeOrange focus:outline-none focus:ring-themeOrange sm:text-sm"
                        placeholder="Code"
                      />
                    </div>
                    <div className="w-full">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-themeOrange focus:outline-none focus:ring-themeOrange sm:text-sm"
                        placeholder="Date/Time"
                      />
                    </div>
                    <div className="w-full">
                         <select
                            id="location"
                            name="location"
                            className="mt-1 block w-full border rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-themeOrange focus:outline-none focus:ring-themeOrange sm:text-sm"
                            defaultValue="Sellers"
                        >
                            <option>Status</option>
                        </select>
                    </div>
                  </div>
                  <div className="mx-auto text-center">
                    <button
                        type="submit"
                        className="text-sm font-medium text-white bg-themeOrange rounded-md px-4 py-1"
                        >
                        Save
                    </button>
                  </div>
                </DialogPanel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  }
  
  export default AddNewCoupon;
  