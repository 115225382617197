import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  ChartBarIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  QueueListIcon,
  CreditCardIcon,
  Cog8ToothIcon,
  MegaphoneIcon,
  FaceSmileIcon,
  BanknotesIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useLocation, Link, Outlet } from "react-router-dom";
import logo from "./../assets/images/logo.png";

import { useDispatch } from "react-redux";  
import { useNavigate } from "react-router-dom"; 
import { logout } from "../redux/slices/authSlice";
import Notification from "./Notification";

const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: false },
  { name: "Registered Users", href: "/users", icon: UsersIcon, current: false },
  {
    name: "Categories",
    href: "/categories",
    icon: QueueListIcon,
    current: false,
  },
  { name: "Home Modules", href: "/modules", icon: HomeIcon, current: false },
  { name: "Auctions", href: "/auctions", icon: InboxIcon, current: false },
  {
    name: "Marketing",
    href: "#",
    icon: MegaphoneIcon,
    current: false,
    children: [
      {
        name: "Notifications",
        href: "/marketing/notifications",
        current: false,
      },
      { name: "Coupons", href: "/marketing/coupons", current: false },
    ],
  },
  {
    name: "Purchases",
    href: "/purchases",
    icon: BanknotesIcon,
    current: false,
  },
  {
    name: "Transactions",
    href: "/transactions",
    icon: CreditCardIcon,
    current: false,
  },
  {
    name: "Feedbacks",
    href: "/feedbacks",
    icon: FaceSmileIcon,
    current: false,
  },
  {
    name: "Reported Accounts",
    href: "/report-accounts",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "System Settings",
    href: "/settings",
    icon: Cog8ToothIcon,
    current: false,
  },
  { name: "Reports", href: "/reports", icon: InboxIcon, current: false },
];

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MainLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null); // State to track the open submenu
  const location = useLocation();

  const dispatch = useDispatch();  // Hook to dispatch actions
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());  // Dispatch logout action
    navigate("/signin");  // Redirect to the signin page
  };

  const toggleMenu = (menuName) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-themeGray" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-themeGray pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img className="h-8 w-auto" src={logo} alt="Your Company" />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <div key={item.name}>
                          {item.children && item.children.length > 0 ? (
                            <button
                              onClick={() =>
                                item.children ? toggleMenu(item.name) : null
                              }
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-theme-gradient text-white"
                                  : "text-themeLightGray hover:bg-gray-700 hover:text-white",
                                "group flex items-center w-full px-2 py-2 text-base font-medium rounded-md"
                              )}
                            >
                              <div className="flex justify-between">
                                <item.icon
                                  className={classNames(
                                    location.pathname === item.href
                                      ? "text-gray-300"
                                      : "text-gray-400 font-normal  group-hover:text-themeLightGray",
                                    "mr-3 flex-shrink-0 h-6 w-6"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </div>
                              <div>
                                {item.children && openMenu === item.name ? (
                                  <ChevronUpIcon className="text-gray-400 w-4" />
                                ) : (
                                  <ChevronDownIcon className="text-gray-400 w-4" />
                                )}
                              </div>
                            </button>
                          ) : (
                            <Link
                              to={item.href}
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-theme-gradient text-white"
                                  : "text-themeLightGray hover:bg-gray-700 hover:text-white",
                                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  location.pathname === item.href
                                    ? "text-gray-300"
                                    : "text-themeLightGray group-hover:text-gray-300",
                                  "mr-4 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          )}
                          {item.children && openMenu === item.name && (
                            <div className="ml-8 space-y-1">
                              {item.children.map((subItem) => (
                                <Link
                                  key={subItem.name}
                                  to={subItem.href}
                                  className={classNames(
                                    location.pathname === subItem.href
                                      ? "bg-theme-gradient text-white"
                                      : "text-themeLightGray hover:bg-gray-700 hover:text-white",
                                    "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                  )}
                                >
                                  {subItem.name}
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          <div className="flex min-h-0 flex-1 flex-col bg-themeGray">
            <div className="flex py-4 flex-shrink-0 items-center bg-themeGray px-4">
              <img className="w-auto" src={logo} alt="Your Company" />
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto">
              <nav className="flex-1 space-y-1 px-2 py-4">
                {navigation.map((item) => (
                  <div key={item.name}>
                    {item.children && item.children.length > 0 ? (
                      <button
                        onClick={() =>
                          item.children ? toggleMenu(item.name) : null
                        }
                        className={classNames(
                          location.pathname === item.href
                            ? "bg-theme-gradient text-white"
                            : "text-themeLightGray font-normal hover:bg-gray-700 hover:text-white",
                          "group flex w-full justify-between items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <div className="flex justify-between">
                          <item.icon
                            className={classNames(
                              location.pathname === item.href
                                ? "text-gray-300"
                                : "text-gray-400 font-normal  group-hover:text-themeLightGray",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                        <div>
                          {item.children && openMenu === item.name ? (
                            <ChevronUpIcon className="text-gray-400 w-4" />
                          ) : (
                            <ChevronDownIcon className="text-gray-400 w-4" />
                          )}
                        </div>
                      </button>
                    ) : (
                      <Link
                        to={item.href}
                        className={classNames(
                          location.pathname === item.href
                            ? "bg-theme-gradient text-white"
                            : "text-themeLightGray font-normal hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            location.pathname === item.href
                              ? "text-gray-300"
                              : "text-gray-400 font-normal  group-hover:text-themeLightGray",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    )}
                    {item.children && openMenu === item.name && (
                      <div className="ml-8 space-y-1">
                        {item.children.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.href}
                            className={classNames(
                              location.pathname === subItem.href
                                ? "bg-theme-gradient text-white"
                                : "text-themeLightGray font-normal hover:bg-gray-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )}
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-themeOrange md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1">
                <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <MagnifyingGlassIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="rounded-full mr-3 bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-themeOrange focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <ChatBubbleOvalLeftEllipsisIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </button>

                <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-themeOrange focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="hidden md:block ml-3 text-right">
                  <span className="text-xs font-medium leading-none">
                    Ali Al Husain
                  </span>
                  <div className="text-xs font-light text-gray-500 leading-none">
                    Admin
                  </div>
                </div>
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-themeOrange focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                            href={item.name === "Sign out" ? undefined : item.href}  // Disable href for Sign out
                            onClick={item.name === "Sign out" ? handleLogout : undefined}  // Handle sign out on click
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">
            <div className="py-4">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-4">
                {children}
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
      <Notification />
    </>
  );
}
