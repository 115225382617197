import React from "react";
import AllUsers from "../../components/Users/AllUsers";
import TitleBar from "../../components/TitleBar";

function Users() {
  return (
    <div>
      <TitleBar title="Registered Users" />
      <div className="mt-4"></div>
      <AllUsers />
    </div>
  );
}

export default Users;
