import React from "react";
import Stats from "../../components/Dashboard/Stats";
import AppUsers from "../../components/Dashboard/AppUsers";
import Bids from "../../components/Dashboard/Bids";
import AuctionStatistics from "../../components/Dashboard/AuctionStatistics";
import Auctions from "../../components/Dashboard/Auctions";

function Dashboard() {
  return (
    <div>
      <div className="">
        <Stats />
      </div>
      <div className="mt-3"></div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div>
          <AppUsers />
        </div>
        <div>
            <Bids />
        </div>
        <div>
            <AuctionStatistics />
        </div>
      </div>
      <div className="mt-3"></div>
      <div className="">
          <Auctions />
      </div>
    </div>
  );
}

export default Dashboard;
