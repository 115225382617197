import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import AddNewBanner from "./Banner/AddNew";
import { fetchBanners, setPage, deleteBanner } from "../../redux/slices/bannersSlice";

export default function AllBanners() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { banners, pagination, loading, error } = useSelector((state) => state.banners);

  useEffect(() => {
    dispatch(fetchBanners({ page: pagination.page, limit: pagination.limit }));
  }, [dispatch, pagination.limit, pagination.page]);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleDelete = (bannerId) => {
    const confirmed = window.confirm("Are you sure you want to delete this banner?");
    if (confirmed) {
      dispatch(deleteBanner(bannerId));
    }
  };

  return (
    <div>
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="font-medium text-lg">Home Banners</h3>
          </div>

          <div className="flex items-center">
            <button onClick={() => setIsOpen(true)} className="flex items-center text-xs font-semibold text-primaryDarkBlue">
              <PlusIcon className="w-4 mr-2" /> Add New
            </button>
          </div>
        </div>

        {/* Show loading or error */}
        {loading && <p>Loading banners...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}

        {!loading && banners.length > 0 && (
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                    <thead>
                      <tr>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8">
                          Sr.#
                        </th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">Name</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">Image</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">Sort Order</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">Status</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 bg-white">
                      {banners.map((banner, index) => (
                        <tr key={banner._id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-semibold">
                            {banner.translations.en.title}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light flex items-center">
                            <img src={banner.coverImage} alt="Banner" className="mr-3 h-12" />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                            {banner.sort}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light uppercase font-medium">
                            {banner.status}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold flex items-center">
                            <div className="mr-3">Edit</div>
                            <div className="cursor-pointer text-red-500" onClick={() => handleDelete(banner._id)}>
                              Delete
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex justify-between py-3 items-center">
              <button
                onClick={() => handlePageChange(pagination.page - 1)}
                disabled={pagination.page === 1}
                className="text-primaryDarkBlue text-xs"
              >
                {pagination.page !== 1 && 'Previous'}
              </button>
              <span className="text-xs">
                Page {pagination.page} of {Math.ceil(pagination.total / pagination.limit)}
              </span>
              <button
                onClick={() => handlePageChange(pagination.page + 1)}
                disabled={pagination.page >= Math.ceil(pagination.total / pagination.limit)}
                className="text-primaryDarkBlue text-xs"
              >
                {pagination.page >= Math.ceil(pagination.total / pagination.limit) ? '' : 'Next'}
              </button>
            </div>
          </div>
        )}

        <AddNewBanner isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
}
