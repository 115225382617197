import userImage from './../../assets/images/user.png';
import pdf from './../../assets/icons/pdf.svg';
import excel from './../../assets/icons/excel.svg';
import { Link } from "react-router-dom";

const users = [
    {
        id: 12136,
        name: "Muhammad Sami",
        accountType: "Normal",
        email: "msami@gmail.com",
        phone: "971-66-55623",
        civilId: "32356456",
        country: "Kuwait",
        IP: "122331132",
        status: "Active"
    },
    {
        id: 12136,
        name: "Muhammad Sami",
        accountType: "Normal",
        email: "msami@gmail.com",
        phone: "971-66-55623",
        civilId: "32356456",
        country: "Kuwait",
        IP: "122331132",
        status: "Active"
    },
    {
        id: 12136,
        name: "Muhammad Sami",
        accountType: "Normal",
        email: "msami@gmail.com",
        phone: "971-66-55623",
        civilId: "32356456",
        country: "Kuwait",
        IP: "122331132",
        status: "Active"
    }

  ]
  
  export default function AllUsers() {
    return (
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
                <h3 className="font-medium text-lg">Users</h3>
          </div>

          <div className="flex justify-end items-center">
            <button className="text-sm text-gray-950 font-medium border-2 border-primaryDarkBlue flex items-center rounded-md px-1 py-1 mr-3">Export <img src={pdf} /> </button>
            <button className="text-sm text-gray-950 font-medium border-2 border-primaryDarkBlue flex items-center rounded-md px-1 py-1">Export <img src={excel} /> </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Account Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Email ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Mobile #
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Civil ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Country
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        IP Address
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Status
                      </th>

                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Actions
                      </th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white">
                    {users.map((user) => (
                      <tr key={user.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                          {user.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light flex items-center"><img src={userImage} className="mr-2 h-6" />{user.name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.accountType}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.email}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.phone}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.civilId}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.country}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.IP}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{user.status}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <Link to={'/users/'+user.id} className="text-primaryDarkBlue hover:text-primaryDarkBlue">
                            Edit<span className="sr-only">, {user.name}</span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  