const stats = [
  { name: 'Total App Users', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
  { name: 'Android/Iphone', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
  { name: 'Seller Accounts', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
  { name: '# of Aunctions', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
  { name: 'Ongoing Auctions', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
  { name: 'Bids Placed', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
  { name: 'Bids Value', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]


export default function Stats() {
  return (
    <div>
      <dl className="mt-5 grid grid-cols-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md md:grid-cols-7 md:divide-y-0 md:divide-x py-2 border border-gray-100 ">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-2 sm:p-3 text-center">
            <dt className="text-base font-semibold text-themeGray">{item.stat}</dt>
            <dd className="mt-1 text-center md:block">
              <div className="text-xs font-medium text-center text-themeSemiGray">
                {item.name}
              </div>
            </dd>
          </div>
        ))}
      </dl>      
    </div>
  )
}
