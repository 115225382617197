import React from "react";
import CountrySelector from "../CountrySelector";
import graph from './../../assets/images/graph.png';

const countries = [
  { name: "kuwait", code: "ku", current: true },
  { name: "United States", code: "us", current: false },
];

export default function AuctionStatistics() {
  return (
    <div className="bg-white px-4 py-4 border border-gray-100 shadow-md rounded-lg">
      {/* Header Area */}
      <div className="flex items-center justify-between">
        <h3 className="font-medium font-base">Auction Statistics</h3>
        <CountrySelector />
      </div>
      <div className="mt-6 mb-20"></div>
        <img src={graph} />
    </div>
  );
}
