import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showNotification } from './notificationSlice';

// Fetch categories
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { getState }) => {
    const { token } = getState().auth;
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/categories`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Create category
export const createCategory = createAsyncThunk(
  'categories/createCategory',
  async (categoryData, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/categories`, categoryData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.category;
    } catch (error) {
      return rejectWithValue(error.response?.data.message || "Failed to create category");
    }
  }
);

// Delete category
export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (categoryId, { getState, dispatch, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/admin/categories/${categoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(showNotification({ message: "Category deleted successfully!", type: "success" }));
      return categoryId;
    } catch (error) {
      const message = error.response?.data.message || "Failed to delete category";
      dispatch(showNotification({ message, type: "error" }));
      return rejectWithValue(message);
    }
  }
);

// Update category
export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async ({ id, data }, { getState, dispatch, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/admin/categories/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(showNotification({ message: "Category updated successfully!", type: "success" }));
      return response.data.category;
    } catch (error) {
      const message = error.response?.data.message || "Failed to update category";
      dispatch(showNotification({ message, type: "error" }));
      return rejectWithValue(message);
    }
  }
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: { categories: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loading = false;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loading = false;
        const newCategory = action.payload;
        if (newCategory.parentId) {
          const parentCategory = state.categories.find(cat => cat._id === newCategory.parentId);
          if (parentCategory) {
            parentCategory.subcategories = parentCategory.subcategories || [];
            parentCategory.subcategories.push(newCategory);
          }
        } else {
          state.categories.push(newCategory);
        }
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        const categoryId = action.payload;
        state.categories = state.categories
          .filter((category) => category._id !== categoryId)
          .map((category) => ({
            ...category,
            subcategories: category.subcategories?.filter((subcat) => subcat._id !== categoryId),
          }));
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        const index = state.categories.findIndex(cat => cat._id === updatedCategory._id);

        if (index !== -1) {
          state.categories[index] = {
            ...state.categories[index],
            ...updatedCategory,
          };
        } else {
          state.categories = state.categories.map(category => {
            if (category.subcategories) {
              return {
                ...category,
                subcategories: category.subcategories.map(subcat =>
                  subcat._id === updatedCategory._id ? updatedCategory : subcat
                )
              };
            }
            return category;
          });
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default categoriesSlice.reducer;
