import React from "react";
import TitleBar from "../../components/TitleBar";
// import AllSlides from "../../components/Modules/AllSlides";
import AllBanners from "../../components/Modules/AllBanners";

function Modules() {
  return (
    <div>
      <TitleBar title="Home Modules" />
      {/* <div className="mt-4"></div>
      <AllSlides /> */}
      <div className="mt-4"></div>
      <AllBanners />
    </div>
  );
}

export default Modules;
