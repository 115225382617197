import "./styles/tailwind.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "./layouts/MainLayout";

// Pages
import SignInPage from "./pages/Auth/SignInPage";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import EditUser from "./pages/Users/edit";
import Categories from "./pages/Categories";
import Modules from "./pages/Modules";
import Auctions from "./pages/Auction";
import Purchases from "./pages/Purchases";
import Transactions from "./pages/Transactions";
import Feedbacks from "./pages/Feedbacks";
import ReportAccounts from "./pages/ReportAccounts";
import Settings from "./pages/Settings";
import Reports from "./pages/Reports";
import ViewAuction from "./pages/Auction/Detail/View";
import Notifications from "./pages/Marketing/Notifications";
import Coupons from "./pages/Marketing/Coupons";

// Components
import PrivateRoute from "./components/Auth/PrivateRoute";

function App() {
  const { token } = useSelector((state) => state.auth);

  return (
    <Router>
      <Routes>
        {/* Redirect from root to login or dashboard */}
        <Route
          path="/"
          element={
            token ? <Navigate to="/dashboard" /> : <Navigate to="/signin" />
          }
        />

        {/* Redirect to dashboard if already logged in */}
        <Route
          path="/signin"
          element={token ? <Navigate to="/dashboard" /> : <SignInPage />}
        />

        {/* Protected Routes - Only accessible if logged in */}
        <Route element={<MainLayout />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:id"
            element={
              <PrivateRoute>
                <EditUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/categories"
            element={
              <PrivateRoute>
                <Categories />
              </PrivateRoute>
            }
          />
          <Route
            path="/modules"
            element={
              <PrivateRoute>
                <Modules />
              </PrivateRoute>
            }
          />
          <Route
            path="/auctions"
            element={
              <PrivateRoute>
                <Auctions />
              </PrivateRoute>
            }
          />
          <Route
            path="/auctions/:id"
            element={
              <PrivateRoute>
                <ViewAuction />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchases"
            element={
              <PrivateRoute>
                <Purchases />
              </PrivateRoute>
            }
          />
          <Route
            path="/transactions"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/feedbacks"
            element={
              <PrivateRoute>
                <Feedbacks />
              </PrivateRoute>
            }
          />
          <Route
            path="/report-accounts"
            element={
              <PrivateRoute>
                <ReportAccounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/marketing/notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/marketing/coupons"
            element={
              <PrivateRoute>
                <Coupons />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
