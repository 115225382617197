import AllLots from "../../../components/Auctions/Lots/AllLots";
import TitleBar from "../../../components/TitleBar";

function ViewAuction() {
  return (
    <div>
      <div>
        <TitleBar title={"Auction Detail"} />
      </div>
      <div className="mt-4"></div>
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="items-center py-2 border-b border-gray-300">
          <div className="md:flex">
            <h3 className="font-medium text-lg mr-6">
              Auction ID: <span className="font-light">1</span>
            </h3>
            <h3 className="font-medium text-lg">
              Title: <span className="font-light">iPhone 14 pro max</span>
            </h3>
          </div>
        </div>
        <div className="grid grid-cols-4 py-3 gap-10 gap-y-4">
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Seller Name:</h4>
              <span className="text-sm font-light">Waseem 008</span>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Country:</h4>
              <span className="text-sm font-light">Kuwait</span>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Duration:</h4>
              <span className="text-sm font-light">23/04/23 to 31/04/23</span>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Type:</h4>
              <span className="text-sm font-light">Normal</span>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Total Bids:</h4>
              <span className="text-sm font-light">190</span>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Total Lots:</h4>
              <span className="text-sm font-light">100</span>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Min Bid Amount:</h4>
              <span className="text-sm font-light">75 KD</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4"></div>
      <AllLots />
    </div>
  );
}

export default ViewAuction;
