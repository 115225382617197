import React from "react";
import TitleBar from "../../components/TitleBar";
import AllAuction from "../../components/Auctions/AllAuction";

function Auctions() {
  return (
    <div>
      <TitleBar title="Auctions" />
      <div className="mt-4"></div>
      <AllAuction title={'In progress'} />
      <div className="mt-4"></div>
      <AllAuction title={'Completed'} />
      <div className="mt-4"></div>
      <AllAuction title={'Blocked'} />
    </div>
  );
}

export default Auctions;
