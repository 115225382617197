import React from "react";
import logo from "./../../assets/images/logo-v.png";

const SignIn = ({ handleSignIn, loading, error }) => {
  return (
    <div className="flex items-center justify-center bg-gray-50 py-40">
      <div className="min-h-screen-minus-20 w-full mx-4 md:mx-0 md:max-w-5xl grid md:grid-cols-2 gap-0 rounded-3xl overflow-hidden shadow-lg">
        <div className="bg-themeGray flex items-center justify-center">
          <img src={logo} className="h-20 md:h-52 py-2 md:py-0" alt="Logo" />
        </div>
        <div className="bg-white p-6 px-16 flex items-center justify-center">
          <div className="w-full">
            <h2 className="text-3xl font-semibold mb-5 text-center text-themeGray">
              Sign In
            </h2>
            <form onSubmit={handleSignIn} className="space-y-5">
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-themeGrayLight"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="username"
                  name="username"
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-themeOrange focus:border-themeOrange sm:text-sm"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-themeGrayLight"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-themeOrange focus:border-themeOrange sm:text-sm"
                  required
                />
              </div>

              {loading && (
                <div className="flex justify-center">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
                </div>
              )}

              {error && <p className="text-red-500 text-center">{error.message}</p>}

              <button
                type="submit"
                className="w-full py-2 px-4 bg-themeOrange text-white font-semibold rounded-xl hover:bg-themeOrange focus:outline-none focus:ring-2 focus:ring-themeOrange text-sm"
                disabled={loading}
              >
                {loading ? 'Signing In...' : 'Sign Me In'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
