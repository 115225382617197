import { PaperClipIcon } from "@heroicons/react/24/outline"
import { current } from "@reduxjs/toolkit"
import React from "react"

const transactions = [
    {
        id: 1,
        sellerUsername: "Waseem",
        type: "Subscription",
        referenceId: "MSD-23223",
        date:"03/80/2023",
        amount: 283.23,
        currency: "USD",
        attachment: "#"
    }

  ]
  
  export default function AllTransactions() {
    return (
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-5">
          <div className="sm:flex-auto">
                <h3 className="font-medium text-lg sr-only">All Transactions</h3>
          </div>

          <div className="flex justify-end items-center">
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        Sr.#
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Seller
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Reference ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Amount
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                        Attachment
                      </th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white">
                    {transactions.map((transaction) => (
                      <tr key={transaction.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                          {transaction.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm   flex items-center text-secondaryBlue font-semibold">{transaction.sellerUsername}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{transaction.type}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{transaction.referenceId}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{transaction.amount + ' ' + transaction.currency}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">{transaction.date}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light"><PaperClipIcon className="w-6 text-secondaryBlue" /></td>


                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  