import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import { addBanner } from "../../../redux/slices/bannersSlice";

function AddNewBanner({ isOpen, setIsOpen }) {
  const [formData, setFormData] = useState({
    sort: "",
    status: "active",
    coverImage: null,
    translations: {
      en: {
        title: "",
        description: ""
      },
      ar: {
        title: "",
        description: ""
      }
    }
  });

  const [imagePreview, setImagePreview] = useState(null); // State for storing image preview URL
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        coverImage: file,
      }));

      // Generate image preview URL
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newBannerData = new FormData();
    newBannerData.append("coverImage", formData.coverImage);
    newBannerData.append("sort", formData.sort);
    newBannerData.append("status", formData.status);
    newBannerData.append("categoryId", "613b3d4470ad4b7e4d5cfc9c"); 
    newBannerData.append("translations[en][title]", formData.translations.en.title);
    newBannerData.append("translations[ar][title]", formData.translations.ar.title);

    dispatch(addBanner(newBannerData));

    // Close modal after submission
    setIsOpen(false);
  };

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="max-w-md space-y-4 bg-white rounded-lg shadow-md p-4">
                <DialogTitle className="text-lg font-medium">Add Home Banner</DialogTitle>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="col-span-full">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Cover photo
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        {imagePreview ? (
                          <img src={imagePreview} alt="Preview" className="h-24 mx-auto mb-2" />
                        ) : (
                          <PhotoIcon
                            aria-hidden="true"
                            className="mx-auto h-12 w-12 text-gray-300"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-themeOrange focus-within:outline-none focus-within:ring-2 focus-within:ring-themeOrange focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload"
                              name="coverImage"
                              type="file"
                              className="sr-only"
                              onChange={handleFileChange}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    name="sort"
                    value={formData.sort}
                    onChange={handleChange}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-themeOrange focus:outline-none focus:ring-themeOrange sm:text-sm"
                    placeholder="Sort"
                  />
                  <input
                    type="text"
                    name="translations[en][title]"
                    value={formData.translations.en.title}
                    onChange={(e) => setFormData((prevData) => ({
                      ...prevData,
                      translations: {
                        ...prevData.translations,
                        en: { ...prevData.translations.en, title: e.target.value },
                      },
                    }))}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-themeOrange focus:outline-none focus:ring-themeOrange sm:text-sm"
                    placeholder="Title (EN)"
                  />
                  <input
                    type="text"
                    name="translations[ar][title]"
                    value={formData.translations.ar.title}
                    onChange={(e) => setFormData((prevData) => ({
                      ...prevData,
                      translations: {
                        ...prevData.translations,
                        ar: { ...prevData.translations.ar, title: e.target.value },
                      },
                    }))}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-themeOrange focus:outline-none focus:ring-themeOrange sm:text-sm"
                    placeholder="Title (AR)"
                  />
                  <div className="text-center">
                    <button
                      type="submit"
                      className="text-sm font-medium text-white bg-themeOrange rounded-md px-4 py-1"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default AddNewBanner;
