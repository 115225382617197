import CountrySelector from "./CountrySelector";

function TitleBar({ title }) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="font-semibold text-2xl text-themeDark">{title}</h2>
        <div className="flex justify-end items-center">
          <CountrySelector />
          <div className="ml-3">
            <span className="text-base text-themeGray">
              06/15/2024 | 09:30 PM
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleBar;
