import React from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const auctions = [
  {
    id: 1,
    title: 'iphone 14 pro',
    seller: 'waseem008',
    country: 'Kuwait',
    duration: {
      date: '24/-6/2024',
      time: '9:00 AM',
    },
    startDuration: {
      date: '22/-6/2024',
      time: '10:00 PM',
    },
    type: 'Normal',
    totalBids: 1200,
    totalLosts: 100,
    status: 'Upcoming'
  },
  {
    id: 1,
    title: 'iphone 14 pro',
    seller: 'waseem008',
    country: 'Kuwait',
    duration: {
      date: '24/-6/2024',
      time: '9:00 AM',
    },
    startDuration: {
      date: '22/-6/2024',
      time: '10:00 PM',
    },
    type: 'Normal',
    totalBids: 1200,
    totalLosts: 100,
    status: 'Upcoming'
  },
  {
    id: 1,
    title: 'iphone 14 pro',
    seller: 'waseem008',
    country: 'Kuwait',
    duration: {
      date: '24/-6/2024',
      time: '9:00 AM',
    },
    startDuration: {
      date: '22/-6/2024',
      time: '10:00 PM',
    },
    type: 'Normal',
    totalBids: 1200,
    totalLosts: 100,
    status: 'Upcoming'
  },
];

export default function AllAuction({title}) {

  return (
    <div>
      <div className="px-4 sm:px-4 lg:px-4 bg-white rounded-lg overflow-hidden">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="font-medium text-lg">{title}</h3>
          </div>

          <div className="flex items-center">
            <button
              className="flex items-center text-xs font-semibold text-primaryDarkBlue"
            >
              <PlusIcon className="w-4 mr-2" /> Add New
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Seller
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Country
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Duration
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Time Start
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Total Bids
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Total Lots
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white">
                    {auctions.map((slide) => (
                      <tr key={slide.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                          {slide.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light flex items-center">
                          {slide.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold">
                          {slide.seller}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {slide.country}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          <div>{slide.duration.date}</div>
                          <div>{slide.duration.time}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                        <div>{slide.startDuration.date}</div>
                        <div>{slide.startDuration.time}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {slide.type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {slide.totalBids}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {slide.totalBids}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                          {slide.status}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondaryBlue font-semibold flex items-center">
                          <Link to={'/auctions/'+slide.id} >View</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
