import React from "react";
import TitleBar from "../../components/TitleBar";
import AllFeedbacks from "../../components/Purchases/AllPurchases";

function Feedbacks() {
  return (
    <div>
      <TitleBar title="Feedbacks" />
      <div className="mt-4"></div>
      <AllFeedbacks />
    </div>
  );
}

export default Feedbacks;
