import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, createCategory, deleteCategory } from "../../redux/slices/categoriesSlice";
import { showNotification } from "../../redux/slices/notificationSlice";
import TitleBar from "../../components/TitleBar";
import { PhotoIcon, TrashIcon, PencilIcon } from "@heroicons/react/20/solid";
import EditCategoryModal from "./../../components/Categories/EditCategoryModal";

function Categories() {
  const dispatch = useDispatch();
  const { categories = [], loading, error } = useSelector((state) => state.categories || {});

  // Form State
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    coverPhoto: null,
    parentId: "",
  });
  const [imagePreview, setImagePreview] = useState(null);

  // Edit Modal State
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, coverPhoto: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("name[en]", formData.name);
    form.append("description[en]", formData.description);
    if (formData.coverPhoto) form.append("coverPhoto", formData.coverPhoto);
    if (formData.parentId) form.append("parentId", formData.parentId);

    dispatch(createCategory(form))
      .unwrap()
      .then(() => {
        dispatch(showNotification({ message: "Category created successfully!", type: "success" }));
        setFormData({ name: "", description: "", coverPhoto: null, parentId: "" });
        setImagePreview(null);
      })
      .catch(() => {
        dispatch(showNotification({ message: "Failed to create category", type: "error" }));
      });
  };

  const handleDelete = (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category and its subcategories?")) {
      dispatch(deleteCategory(categoryId))
        .unwrap()
        .then(() => {
          dispatch(showNotification({ message: "Category deleted successfully!", type: "success" }));
        })
        .catch(() => {
          dispatch(showNotification({ message: "Failed to delete category", type: "error" }));
        });
    }
  };

  const openEditModal = (category) => {
    setCategoryToEdit(category); // Set category to edit
    setIsEditModalOpen(true);
  };

  return (
    <div>
      <TitleBar title="Categories" />
      <div className="mt-4"></div>

      <div className="grid grid-cols-5 gap-4">
        {/* New Category Form */}
        <div className="col-span-2">
          <div className="px-4 sm:px-4 lg:px-4 py-4 bg-white shadow-md rounded-lg overflow-hidden">
            <h3 className="font-medium text-lg mb-3">New Category</h3>
            <form onSubmit={handleSubmit}>
              <div className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-themeOrange focus:outline-none sm:text-sm"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                    Cover photo
                  </label>
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 relative">
                    <input
                      id="file-upload"
                      name="coverPhoto"
                      type="file"
                      onChange={handleFileChange}
                      className="absolute inset-0 opacity-0 cursor-pointer"
                    />
                    {imagePreview ? (
                      <img src={imagePreview} alt="Preview" className="mx-auto h-24 w-24 object-cover rounded-md" />
                    ) : (
                      <div className="text-center">
                        <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                        <div className="mt-4 text-sm leading-6 text-gray-600">Upload a file</div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="px-3 py-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeOrange sm:text-sm sm:leading-6"
                  />
                </div>

                <div>
                  <label htmlFor="parentId" className="block text-sm font-medium leading-6 text-gray-900">
                    Parent Category
                  </label>
                  <select
                    id="parentId"
                    name="parentId"
                    value={formData.parentId}
                    onChange={handleInputChange}
                    className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-themeOrange focus:outline-none sm:text-sm"
                  >
                    <option value="">None</option>
                    {categories
                      .filter((cat) => !cat.parentId)
                      .map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name.en}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button disabled={loading} type="submit" className="text-sm font-medium text-white bg-themeOrange rounded-md px-4 py-1">
                  { loading ? 'Creating...' : 'Create' }
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Categories List */}
        <div className="col-span-3">
          <div className="px-4 sm:px-4 lg:px-4 bg-white shadow-md rounded-lg overflow-scroll">
            <div className="sm:flex sm:items-center py-2">
              <h3 className="font-medium text-lg">All Categories</h3>
            </div>

            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto">
                <div className="py-2 min-w-full align-middle">
                  <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 rounded-lg">
                    {loading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p className="text-red-500">Error: {error}</p>
                    ) : (
                      <table className="min-w-full divide-y divide-gray-100">
                        <thead>
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray">
                              Name
                            </th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                              Description
                            </th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                              Slug
                            </th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-100 bg-white">
                          {categories.map((category) => (
                            <React.Fragment key={category._id}>
                              <tr>
                                <td className="py-4 pl-4 pr-3 text-sm font-light text-themeDark">
                                  {category.name.en}
                                </td>
                                <td className="px-3 py-4 text-sm text-themeDark font-light">
                                  {category.description.en}
                                </td>
                                <td className="px-3 py-4 text-sm text-themeDark font-light">
                                  {category.slug}
                                </td>
                                <td className="px-3 py-4 text-sm text-primaryBlue font-semibold flex items-center space-x-4">
                                  <PencilIcon
                                    className="h-5 w-5 text-blue-500 cursor-pointer"
                                    onClick={() => openEditModal(category)}
                                    title="Edit category"
                                  />
                                  <TrashIcon
                                    className="h-5 w-5 text-red-500 cursor-pointer"
                                    onClick={() => handleDelete(category._id)}
                                    title="Delete category"
                                  />
                                </td>
                              </tr>
                              {category.subcategories?.map((subcat) => (
                                <tr key={subcat._id}>
                                  <td className="py-4 pl-10 text-sm text-themeDark">
                                    -- {subcat.name.en}
                                  </td>
                                  <td className="px-3 py-4 text-sm text-themeDark font-light">
                                    {subcat.description.en}
                                  </td>
                                  <td className="px-3 py-4 text-sm text-themeDark font-light">
                                    {subcat.slug}
                                  </td>
                                  <td className="px-3 py-4 text-sm text-primaryBlue font-semibold flex items-center space-x-4">
                                    <PencilIcon
                                      className="h-5 w-5 text-blue-500 cursor-pointer"
                                      onClick={() => openEditModal(subcat)}
                                      title="Edit subcategory"
                                    />
                                    <TrashIcon
                                      className="h-5 w-5 text-red-500 cursor-pointer"
                                      onClick={() => handleDelete(subcat._id)}
                                      title="Delete subcategory"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Category Modal */}
      {categoryToEdit && (
        <EditCategoryModal
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          category={categoryToEdit}
        />
      )}
    </div>
  );
}

export default Categories;
